<template>
  <TheLoader v-if="loading" />
  <div class="k-page-wrapper" id="k-page-wrapper" v-if="background">
    <div class="kpage-builder-body">
      <div class="kpbf-wrapper" style="padding: 160px 0px 110px 0px">
        <div class="kpbf-background-wrap">
          <div
            class="kpbf-background kparallax kjs"
            :style="`
              background-image: url(${photoPath + background.photo});
              background-size: cover;
              background-position: center;`"
            data-parallax-speed="0"
          ></div>
        </div>
        <div class="kpbf-wrapper-content kjs">
          <div class="kpbf-wrapper-container clearfix kcontainer">
            <div class="kpbf-column kcolumn-30 kcolumn-first" id="kcolumn-1">
              <div
                class="kpbf-column-content-margin kjs"
                style="padding: 190px 0px 30px 80px"
              >
                <div class="kpbf-column-content clearfix kjs">
                  <div class="kpbf-element">
                    <div
                      class="
                        ktitle-item
                        kitem-pdb
                        clearfix
                        kleft-align
                        ktitle-item-caption-bottom
                        kitem-pdlr
                      "
                      style="padding-bottom: 35px"
                    >
                      <div class="ktitle-item-title-wrap">
                        <h3
                          class="ktitle-item-title kskin-title"
                          style="
                            font-size: 50px;
                            font-weight: 700;
                            letter-spacing: 0px;
                            text-transform: none;
                            color: #ffffff;
                          "
                        >
                          {{ background.title }}
                          <span
                            class="ktitle-item-title-divider kskin-divider"
                          ></span>
                        </h3>
                      </div>
                      <span
                        class="ktitle-item-caption kinfo-font kskin-caption"
                        style="
                          font-size: 30px;
                          font-style: normal;
                          color: #fff;
                          margin-top: 20px;
                        "
                      >
                        {{ background.content }}
                      </span>
                    </div>
                  </div>
                  <div class="kpbf-element">
                    <div class="kbutton-item kitem-pdlr kitem-pdb kleft-align">
                      <router-link
                        class="kbutton kbutton-gradient kbutton-no-border"
                        to="/about"
                        style="
                          font-weight: 800;
                          letter-spacing: 1px;
                          padding: 15px 34px 18px 34px;
                          border-radius: 27px;
                          -moz-border-radius: 27px;
                          -webkit-border-radius: 27px;
                          background: #f58823;
                          color: #fff;
                        "
                        ><span class="kcontent">{{
                          lang.learnMore
                        }}</span></router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="kpbf-wrapper"
        style="margin: -60px 0px 0px 0px; padding: 0px 0px 0px 0px"
        id="kwrapper-2"
        v-if="main"
      >
        <div class="kpbf-background-wrap"></div>
        <div class="kpbf-wrapper-content kjs">
          <div class="kpbf-wrapper-container clearfix kcontainer">
            <div
              class="kpbf-wrapper-container-inner kitem-mglr clearfix"
              style="
                box-shadow: 0 0 35px rgba(10, 10, 10, 0.12);
                -moz-box-shadow: 0 0 35px rgba(10, 10, 10, 0.12);
                -webkit-box-shadow: 0 0 35px rgba(10, 10, 10, 0.12);
                background-color: #ffffff;
                border-radius: 3px 3px 3px 3px;
                -moz-border-radius: 3px 3px 3px 3px;
                -webkit-border-radius: 3px 3px 3px 3px;
                z-index: 999;
              "
            >
              <div class="kpbf-column kcolumn-24 kcolumn-first">
                <div
                  class="kpbf-column-content-margin textpadding kjs"
                  style="padding: 50px 0px 20px 0px"
                  data-sync-height="map-height"
                >
                  <div
                    class="
                      kpbf-column-content
                      clearfix
                      kjs
                      ksync-height-content
                    "
                  >
                    <div class="kpbf-element">
                      <div
                        class="kimage-item kitem-pdlr kitem-pdb kcenter-align"
                      >
                        <div
                          class="
                            kimage-item-wrap
                            kmedia-image
                            kimage-item-style-rectangle
                          "
                          style="border-width: 0px"
                        >
                          <img
                            :src="photoPath + main.photo"
                            alt
                            width="444"
                            height="221"
                            title="hp-1-map"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="kpbf-column kcolumn-36" id="kcolumn-3">
                <div
                  class="kpbf-column-content-margin kjs"
                  style="padding: 90px 40px 0px 0px"
                  data-sync-height="map-height"
                >
                  <div
                    class="
                      kpbf-column-content
                      clearfix
                      kjs
                      ksync-height-content
                    "
                  >
                    <div class="kpbf-element">
                      <div
                        class="
                          ktitle-item
                          kitem-pdb
                          clearfix
                          kleft-align
                          ktitle-item-caption-top
                          kitem-pdlr
                        "
                        style="padding-bottom: 25px"
                      >
                        <div class="ktitle-item-title-wrap">
                          <h3
                            class="ktitle-item-title kskin-title"
                            style="
                              font-size: 31px;
                              font-weight: 700;
                              letter-spacing: 0px;
                              text-transform: none;
                              color: #f58823;
                            "
                          >
                            {{ main.title }}
                            <span
                              class="ktitle-item-title-divider kskin-divider"
                            ></span>
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div class="kpbf-element">
                      <div
                        class="
                          ktext-box-item
                          kitem-pdlr kitem-pdb
                          kleft-align
                          kno-p-space
                        "
                      >
                        <div
                          class="ktext-box-item-content"
                          style="
                            font-size: 17px;
                            text-transform: none;
                            color: #4f5d77;
                          "
                        >
                          <p>{{ main.content }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="kpbf-wrapper"
        style="margin: 0px 0px 0px 0px; padding: 115px 0px 120px 0px"
        id="kwrapper-3"
      >
        <div class="kpbf-background-wrap">
          <div
            class="kpbf-background kparallax kjs"
            style="
              background-image: url(images/hp-accordion-bg.jpg);
              background-size: cover;
              background-position: center;
            "
            data-parallax-speed="0.2"
          ></div>
        </div>
        <div class="kpbf-wrapper-content kjs" v-if="texts">
          <div class="kpbf-wrapper-container clearfix kcontainer kservices">
            <HomeTab
              v-for="(tab, index) in texts"
              :key="index"
              :title="tab.title"
              :icon="photoPath + tab.photo"
              :content="tab.content"
            />
          </div>
        </div>
      </div>

      <Services />
    </div>
  </div>
</template>

<script>
import HomeTab from "../components/HomeTab.vue";
import Services from "../components/Services.vue";
import TheLoader from "../components/TheLoader.vue";

export default {
  inject: ["lang", "photoPath"],
  data() {
    return {
      loading: true,
    };
  },
  components: {
    HomeTab,
    Services,
    TheLoader,
  },
  async mounted() {
    await this.$store.dispatch("home");
    this.loading = false;
  },
  computed: {
    main() {
      return this.$store.getters.main;
    },
    language() {
      return this.$store.getters.language;
    },
    texts() {
      return this.$store.getters.texts;
    },
    background() {
      return this.$store.getters.background;
    },
  },
};
</script>
