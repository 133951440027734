<template>
  <TheLoader v-if="loading" />
  <div
    v-if="background"
    class="k-page-title-wrap k-style-custom k-center-align"
    :style="`background-image: url(${photoPath + background.photo})`"
  >
    <div class="k-header-transparent-substitute"></div>
    <div class="k-page-title-overlay"></div>
    <div class="k-page-title-container k-container">
      <div class="k-page-title-content k-item-pdlr">
        <h1 class="k-page-title" style="text-align: left">
          <br /><br />
          {{ background.title }}
        </h1>
        <div class="k-page-caption" style="text-align: left">
          {{ background.content }}
        </div>
      </div>
    </div>
  </div>
  <div class="k-page-wrapper" id="k-page-wrapper">
    <div class="kpage-builder-body">
      <div
        class="kpbf-wrapper"
        style="
          padding: 4px 0px 26px 0px;
          border-width: 0px 0px 1px 0px;
          border-color: #ededed;
          border-style: solid;
        "
      >
        <div class="kpbf-background-wrap"></div>
        <div class="kpbf-wrapper-content kjs">
          <div class="kpbf-wrapper-container clearfix kcontainer">
            <div class="kpbf-element">
              <div
                class="kbreadcrumbs-item kitem-pdlr kitem-pdb"
                style="padding-bottom: 0px"
              >
                <a class="home">
                  <span>{{ lang.home }}</span>
                </a>

                /
                <a class="post post-page">
                  <span> {{ lang.careers }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="kpbf-sidebar-wrapper">
        <div
          class="kpbf-sidebar-container kline-height-0 clearfix kjs kcontainer"
        >
          <div
            class="kpbf-sidebar-padding kline-height"
            style="padding: 50px 0px 30px 0px"
          >
            <div class="kpbf-sidebar-content-inner">
              <div class="kpbf-element">
                <div
                  class="
                    ktitle-item
                    kitem-pdb
                    clearfix
                    kleft-align
                    ktitle-item-caption-top
                    kitem-pdlr
                  "
                  style="padding-bottom: 35px"
                >
                  <div class="ktitle-item-title-wrap">
                    <h3
                      class="ktitle-item-title kskin-title"
                      style="
                        font-size: 27px;
                        font-weight: 700;
                        letter-spacing: 0px;
                        text-transform: none;
                        color: #202020;
                      "
                    >
                      {{ lang.openVacancies }}
                      <span
                        class="ktitle-item-title-divider kskin-divider"
                      ></span>
                    </h3>
                  </div>
                </div>
              </div>
              <div class="kpbf-element">
                <div class="ktext-box-item kitem-pdb kleft-align kno-p-space">
                  <div
                    class="ktext-box-item-content"
                    style="
                      font-size: 17px;
                      font-weight: 400;
                      text-transform: none;
                      color: #777777;
                    "
                  >
                    <p v-if="noCareers">
                      {{ lang.noJobs }}
                      <br /><br /><br /><br />
                    </p>
                    <div v-else>
                      <div class="career-grid">
                        <div v-for="(career, index) in careers" :key="index">
                          <div class="career-card">
                            <h6>
                              <strong>{{ career.title }}</strong>
                            </h6>
                            <div>
                              <div>
                                <p>{{ lang.published }}</p>
                                <p>{{ career.published }}</p>
                              </div>
                              <div>
                                <p>{{ lang.expiration }}</p>
                                <p>{{ career.expiration }}</p>
                              </div>
                              <a :href="filePath + career.file" download>
                                {{ lang.download }}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheLoader from "../components/TheLoader.vue";

export default {
  components: { TheLoader },
  inject: ["lang", "photoPath"],
  data() {
    return {
      loading: true,
      filePath: "https://bandacargo.kurdos.co/pdf/",
    };
  },
  async mounted() {
    await this.$store.dispatch("careers/careers");
    this.loading = false;
  },
  computed: {
    careers() {
      return this.$store.getters["careers/careers"];
    },
    noCareers() {
      return this.careers.length === 0;
    },
    background() {
      return this.$store.getters["careers/background"];
    },
  },
};
</script>

<style scoped>
table {
  width: 100%;
}

td {
  padding: 10px;
}

.title,
.exp {
  width: 20%;
  min-width: 20px;
}

.file {
  width: 60%;
}

.career-grid {
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
}

.career-grid > div {
  width: 100%;
  padding: 15px;
}

.career-card {
  padding: 30px;
  box-shadow: 0px 0px 8px -1px #00000045;
}

.career-card h6 {
  margin-bottom: 30px;
}

.career-card a {
  margin-top: 20px;
  display: inline-block;
}

.career-card > div > div {
  margin-top: 10px;
}

@media only screen and (max-width: 900px) {
  .career-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 600px) {
  .career-grid {
    grid-template-columns: 1fr;
  }
}
</style>