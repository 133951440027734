<template>
  <div
    class="ktab-feature-title-background kwith-active item-bg"
    :style="`background-image: url(${background})`"
  ></div>
  <div
    class="ktab-feature-title-background-active item-bg"
    :style="`background-image: url(${background})`"
  ></div>
  <div class="ktab-feature-title-background-overlay"></div>
  <div class="ktab-feature-title-background-overlay-active"></div>
  <div
    class="ktab-feature-title-image"
    style="max-width: 56px; margin-left: auto; margin-right: auto"
  >
    <img :src="icon" alt width="110" height="108" title="tab-1-icon-n" />
  </div>
  <h3 class="ktab-feature-title">{{ title }}</h3>
  <div class="ktab-feature-caption">{{ subtitle }}</div>
</template>

<script>
export default {
  props: ["title", "subtitle", "icon", "background", "active"],
};
</script>
