import useApi from '../useApi'

export default {
  namespaced: true,
  state: {
    services: [],
    background: null
  },
  getters: {
    services: state => { return state.services },
    background: state => { return state.background }
  },
  mutations: {
    setServices: (state, payload) => { state.services = payload },
    setBackground: (state, payload) => { state.background = payload }
  },
  actions: {
    services: async context => {
      const url = context.rootGetters.url('services')
      const response = await useApi.get(url)
      context.commit('setServices', response.services)
    },
    background: async context => {
      const url = context.rootGetters.url('services/bg')
      const response = await useApi.get(url)
      context.commit('setBackground', response.background)
    }
  },
}