<template>
  <div class="k-header-background-transparent">
    <div class="k-top-bar">
      <div class="k-top-bar-container k-top-bar-full">
        <div class="k-top-bar-container-inner clearfix">
          <div class="k-top-bar-left k-item-pdlr">
            <div class="kdropdown-tab kjs clearfix">
              <div class="kdropdown-tab-title">
                <span class="khead">{{ lang.activeLang }}</span>
                <div class="kdropdown-tab-head-wrap" style="z-index: 9999">
                  <div
                    @click="changeLanguage('en')"
                    class="kdropdown-tab-head"
                    :class="{ kactive: enActive }"
                    data-index="0"
                  >
                    {{ lang.english }}
                  </div>
                  <div
                    @click="changeLanguage('ar')"
                    class="kdropdown-tab-head"
                    :class="{ kactive: arActive }"
                    data-index="1"
                  >
                    {{ lang.arabic }}
                  </div>
                  <div
                    @click="changeLanguage('ku')"
                    class="kdropdown-tab-head"
                    :class="{ kactive: kuActive }"
                    data-index="2"
                  >
                    {{ lang.kurdish }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="k-top-bar-left k-item-pdlr" v-if="info">
            <div class="kdropdown-tab kjs clearfix">
              <div class="kdropdown-tab-content-wrap">
                <div class="kdropdown-tab-content kactive" data-index="0">
                  <i class="fa fa-envelope-open" id="i_fdfd_1"></i>
                  <a :href="`mailto:${info.email}`">{{ info.email }}</a>
                  <div id="div_fdfd_1">|</div>
                  <i class="fa fa-phone" id="i_fdfd_2"></i>{{ info.main_phone }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <header
      class="
        k-header-wrap
        k-header-style-plain
        k-style-menu-right
        k-sticky-navigation
        k-style-slide
      "
      data-navigation-offset="75"
    >
      <div class="k-header-background"></div>
      <div class="k-header-container k-header-full">
        <div class="k-header-container-inner clearfix">
          <div class="k-logo k-item-pdlr">
            <div class="k-logo-inner">
              <router-link class to="/"
                ><img src="images/bandalogo-1.png" style="width: 120px"
              /></router-link>
            </div>
          </div>
          <div class="k-navigation k-item-pdlr clearfix">
            <div class="k-main-menu" id="k-main-menu">
              <ul id="menu-main-navigation-1" class="sf-menu">
                <li class="menu-item menu-item-home k-normal-menu">
                  <router-link to="/">{{ lang.home }}</router-link>
                </li>
                <li class="menu-item menu-item-home k-normal-menu">
                  <router-link to="/about">{{ lang.about }}</router-link>
                </li>
                <li class="menu-item k-normal-menu">
                  <router-link to="/services" class="sf-with-ul-pre">
                    {{ lang.ourServices }}
                  </router-link>
                </li>
                <li class="menu-item menu-item-home k-normal-menu">
                  <router-link to="/careers">{{ lang.careers }}</router-link>
                </li>

                <li class="menu-item menu-item-home k-normal-menu">
                  <router-link to="/contact">{{ lang.contactUs }}</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>


<script>
export default {
  emits: ["changeLang"],
  inject: ["lang", "language"],
  computed: {},
  methods: {
    changeLanguage(lang) {
      this.$emit("changeLang", lang);
    },
  },
  computed: {
    enActive() {
      return this.language === "en";
    },
    kuActive() {
      return this.language === "ku";
    },
    arActive() {
      return this.language === "ar";
    },
    info() {
      return this.$store.getters["contact/info"];
    },
  },
};
</script>
