<template>
  <TheLoader v-if="loading" />
  <MobileNav @changeLang="changeLang" />
  <div class="k-body-outer-wrapper">
    <div class="k-body-wrapper clearfix k-with-transparent-header k-with-frame">
      <TopNav @changeLang="changeLang" />
      <router-view />
    </div>
  </div>
  <TheFooter />
</template>

<script>
import TheFooter from "./components/TheFooter.vue";
import TopNav from "./components/TopNav.vue";
import MobileNav from "./components/MobileNav.vue";
import languages from "./languages";
import TheLoader from "./components/TheLoader.vue";

export default {
  components: { TheFooter, MobileNav, TopNav, TheLoader },
  data() {
    return {
      loading: true,
    };
  },
  beforeCreate() {
    let lang = localStorage.getItem("lang");
    if (!lang || lang == "" || lang == "null" || lang == "undefined") {
      localStorage.setItem("lang", "en");
    }
  },
  async mounted() {
    let lang = localStorage.getItem("lang");
    if (!lang || lang == "" || lang == "null" || lang == "undefined") {
      this.$store.dispatch("setLanguage", "en");
    } else {
      this.$store.dispatch("setLanguage", lang);
    }
    await this.$store.dispatch("contact/contact");
    this.loading = false;
    this.$nextTick(() => {
      this.checkLayout();
    });
  },
  computed: {
    language() {
      return !localStorage.getItem("lang") ||
        localStorage.getItem("lang") === ""
        ? "en"
        : localStorage.getItem("lang");
    },
  },
  provide() {
    return {
      lang: languages[this.language],
      language: this.language,
      photoPath: "https://bandacargo.kurdos.co/images/",
    };
  },
  methods: {
    changeLang(lang) {
      if (lang !== localStorage.getItem("lang")) {
        localStorage.setItem("lang", lang);
        this.$router.go();
      }
    },
    checkLayout() {
      let lang = localStorage.getItem("lang");
      if (lang == "en") {
        document.getElementById("app").classList.remove(["rtl", "ku", "ar"]);
      } else if (lang == "ku") {
        document.getElementById("app").classList.add("rtl");
        document.getElementById("app").classList.add("ku");
        document.getElementById("app").classList.remove("ar");
      } else if (lang == "ar") {
        document.getElementById("app").classList.add("rtl");
        document.getElementById("app").classList.add("ar");
        document.getElementById("app").classList.remove("ku");
      }
    },
  },
};
</script>
