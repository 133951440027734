const english = {
  activeLang: "English",
  english: "English",
  kurdish: "Kurdish",
  arabic: "Arabic",
  home: "HOME",
  about: "ABOUT",
  ourServices: "OUR SERVICES",
  careers: "CAREERS",
  contactUs: "CONTACT US",
  learnMore: "LEARN MORE",
  globalDoorToDoor: "Global Door to Door",
  globalDoorToDoorDesc: "shipping service",
  airCargo: "Air Cargo",
  airCargoDesc: "consolidated, express, door-to-door services",
  maritimeTransport: "Maritime Transport",
  maritimeTransportDesc: "We reach all the ports on earth.",
  roadTransport: "Road Transport",
  roadTransportDesc: "International or Domestic Transactions",
  clearanceServices: "Clearance Services",
  clearanceServicesDesc: "for all state ports of import and export",
  footerDesc: "2021 © B&A Cargo | Developed by Black Ace",
  aboutUs: "ABOUT US",
  internationalLogisticsServices: "International Logistics Services",
  careerDesc: "Join our team & work with us",
  openVacancies: "Open Vacancies",
  AddressEmailAndPhone: "Address, Email and Phone",
  callUs: "Call us",
  emailUs: "Email us",
  findUs: "Find us",
  viewOnMap: "View On Map",
  noJobs: "We currently don't have any open jobs",
  published: "Publish Date:",
  expiration: "Expiration Date:",
  download: "Download",
};

const arabic = {
  activeLang: "العربیة",
  english: "English",
  kurdish: "کوردی",
  arabic: "العربیة",
  home: "الصفحة الرئيسية",
  about: "معلومات عنا",
  ourServices: "خدماتنا",
  careers: "وظائف",
  contactUs: "اتصل بنا",
  learnMore: "مزید",
  globalDoorToDoor: "Global Door to Door",
  globalDoorToDoorDesc: "خدمة الشحن",
  airCargo: "الشحن الجوي",
  airCargoDesc: "خدمات موحدة وسريعة ومن الباب إلى الباب",
  maritimeTransport: "النقل البحرى",
  maritimeTransportDesc: "نصل إلى جميع الموانئ على وجه الأرض.",
  roadTransport: "النقل على الطرق",
  roadTransportDesc: "المعاملات الدولية أو المحلية",
  clearanceServices: "Clearance Services",
  clearanceServicesDesc: "for all state ports of import and export",
  footerDesc: " تم تطوير من قبل | B&A Cargo  ©",
  aboutUs: "معلومات عنا",
  internationalLogisticsServices: "الخدمات اللوجستية الدولية",
  careerDesc: "انضم إلى فريقنا واعمل معنا",
  openVacancies: "وظائف شاغرة",
  AddressEmailAndPhone: "العنوان والبريد الإلكتروني والهاتف",
  callUs: "اتصل بنا",
  emailUs: "ارسل لنا عبر البريد الإلكتروني",
  findUs: "عنواننا",
  viewOnMap: "عرض على الخريطة",
  noJobs: "ليس لدينا حاليا أي وظائف مفتوحة",
  published: "يوم النشر:",
  expiration: "یوم الأنتهاء:",
  download: "تنزیل",
};

const kurdish = {
  activeLang: "کوردی",
  english: "English",
  kurdish: "کوردی",
  arabic: "العربية",
  home: "سەرەتا",
  about: "دەربارە",
  ourServices: "خزمەتگوزاریەکانمان",
  careers: "هەلی کار",
  contactUs: "پەیوەندی",
  learnMore: "زیاتر بزانە",
  globalDoorToDoor: "Global Door to Door",
  globalDoorToDoorDesc: "خزمەتگوزاری گواستنەوە",
  airCargo: "گواستنەوەی ئاسمانی",
  airCargoDesc: "consolidated, express, door-to-door services",
  maritimeTransport: "گواستنەوەی دەریایی",
  maritimeTransportDesc: "دەتگەیەنین بە هەموو بەندەرەکان",
  roadTransport: "گواستنەوەی وشکانی",
  roadTransportDesc: "International or Domestic Transactions",
  clearanceServices: "Clearance Services",
  clearanceServicesDesc: "for all state ports of import and export",
  footerDesc: " پەرەیپێدراوە لە لایەن | B&A Company ©",
  aboutUs: "دەربارەی ئێمە",
  internationalLogisticsServices: "International Logistics Services",
  careerDesc: "بەشداربە لە تیمەکامان و کارمان لەگەڵ بکە",
  openVacancies: "هەلی کارەکان",
  AddressEmailAndPhone: "ناونیشان ، ژمارەی تەلەفون و ئیمەیڵ",
  callUs: "پەیوەندیمان پێوە بکە",
  emailUs: "ئیمەیڵ بکە",
  findUs: "ناونیشانمان",
  viewOnMap: "لەسەر نەخشە نیشانی بدە",
  noJobs: "لە ئێستادا هیچ هەلێکی کارمان نییە",
  published: "رۆژی بڵاوکردنەوە:",
  expiration: "رۆژی بەسەرچوون:",
  download: "داگرتن",
};

export default {
  ku: kurdish,
  ar: arabic,
  en: english,
};
