import useApi from '../useApi'

export default {
  namespaced: true,
  state: {
    careers: [],
    background: null,
  },
  getters: {
    careers: state => { return state.careers },
    background: state => { return state.background }
  },
  mutations: {
    setCareers: (state, payload) => {
      if (payload.careers)
        state.careers = payload.careers
      state.background = payload.background
    },
  },
  actions: {
    careers: async context => {
      const url = context.rootGetters.url('careers')
      const response = await useApi.get(url)
      context.commit('setCareers', response)
    }
  },
}