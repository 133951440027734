<template>
  <div class="kpbf-wrapper" style="padding: 0px 0px 0px 0px">
    <div class="kpbf-background-wrap" style="background-color: #0a0a0a"></div>
    <div class="kpbf-wrapper-content kjs">
      <div class="kpbf-wrapper-container clearfix kpbf-wrapper-full-no-space">
        <div class="kpbf-element" v-if="services">
          <div
            class="ktab-feature-item kjs kitem-pdb kitem-pdlr"
            style="padding-bottom: 0px"
            id="ktab-feature-1"
          >
            <div class="ktab-feature-title-item-wrap clearfix services">
              <div
                data-tab-id="1"
                data-sync-height="ktab-feature-1-title"
                v-for="(service, index) in services"
                :key="index"
                :class="cssClass(index)"
                @click="changeService(index)"
              >
                <ServiceTab
                  :title="service.title"
                  :subtitle="service.subtitle"
                  :icon="photoPath + service.icon"
                  :background="photoPath + service.background"
                  :active="activeIndex == index"
                />
              </div>
            </div>
            <div class="ktab-feature-item-content-wrap clearfix">
              <div
                style="height: auto !important"
                class="ktab-feature-content-wrap kcontainer kactive kjs"
              >
                <div class="ktab-feature-content-left">
                  <h3 class="ktab-feature-content-left-title">
                    {{ serviceTitle }}
                  </h3>
                  <div class="ktab-feature-content-left-caption ktitle-font">
                    {{ serviceSubtitle }}
                  </div>
                </div>
                <div class="ktab-feature-content-right">
                  <div
                    style="font-size: 20px; color: #fafafa"
                    v-html="serviceContent"
                  ></div>
                  <span
                    class="kspace-shortcode"
                    style="margin-top: 20px"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceTab from "../components/ServiceTab.vue";

export default {
  inject: ["photoPath"],
  components: { ServiceTab },
  data() {
    return {
      activeIndex: 0,
    };
  },
  async mounted() {
    await this.$store.dispatch("services/services");
  },
  computed: {
    services() {
      return this.$store.getters["services/services"];
    },
    serviceTitle() {
      return this.services[this.activeIndex]?.title;
    },
    serviceSubtitle() {
      return this.services[this.activeIndex]?.subtitle;
    },
    serviceContent() {
      return this.services[this.activeIndex]?.content;
    },
  },
  methods: {
    changeService(index) {
      this.activeIndex = index;
    },
    cssClass(index) {
      return index == this.activeIndex
        ? "ktab-feature-title-wrap ktab-feature-size-4 kactive kjs item-bg"
        : "ktab-feature-title-wrap ktab-feature-size-4 kjs item-bg";
    },
  },
};
</script>

<style scoped>
.services {
  height: 360px;
}

.item-bg {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 850px) {
  .services {
    height: 180px;
  }

  .item-bg {
    padding: 20px 10px;
  }
}

@media only screen and (max-width: 767px) {
  .services {
    height: 100px;
  }

  .item-bg {
    padding: 20px 10px 0px;
  }
}
</style>