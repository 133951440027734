<template>
  <div class="kpbf-column kcolumn-20">
    <div class="kpbf-column-content-margin kjs">
      <div class="kpbf-column-content clearfix kjs">
        <div class="kpbf-element">
          <div class="kicon-list-item kitem-pdlr kitem-pdb clearfix">
            <ul>
              <li class="kskin-divider" style="margin-bottom: 50px">
                <span class="kicon-list-image" style="margin-right: 38px"
                  ><img :src="icon" alt width="70" title="clock-icon"
                /></span>
                <div class="kicon-list-content-wrap">
                  <span
                    class="kicon-list-content"
                    style="color: #252525; font-size: 20px; font-weight: 700"
                    >{{ title }}</span
                  ><span
                    class="kicon-list-caption"
                    style="color: #4f5d77; font-size: 16px; font-weight: 400"
                    >{{ content }}</span
                  >
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["icon", "title", "content"],
};
</script>