import useApi from '../useApi'

export default {
  namespaced: true,
  state: {
    about: null,
    background: null
  },
  getters: {
    about: state => { return state.about },
    background: state => { return state.background }
  },
  mutations: {
    setAbout: (state, payload) => {
      state.about = payload.about
      state.background = payload.background
    }
  },
  actions: {
    about: async context => {
      const url = context.rootGetters.url('about')
      const response = await useApi.get(url)
      context.commit('setAbout', response)
    }
  }
}