import useApi from '../useApi'

export default {
  namespaced: true,
  state: {
    addresses: [],
    info: null,
    background: null,
  },
  getters: {
    info: state => { return state.info },
    addresses: state => { return state.addresses },
    background: state => { return state.background }
  },
  mutations: {
    setContact: (state, payload) => {
      state.info = payload.info
      state.addresses = payload.addresses
      state.background = payload.background
    }
  },
  actions: {
    contact: async context => {
      const url = context.rootGetters.url('contact')
      const response = await useApi.get(url)
      context.commit('setContact', response)
    }
  },
}