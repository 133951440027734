<template>
  <div
    class="k-page-title-wrap k-style-custom k-center-align"
    style="background-image: url(/images/contact.jpg); height: 100vh"
  >
    <div class="k-header-transparent-substitute"></div>
    <div class="k-page-title-overlay"></div>
    <div class="k-page-title-container k-container">
      <div class="k-page-title-content k-item-pdlr">
        <h1 class="k-page-title" style="text-align: left">
          <br />
          <span class="error"> 404 </span>
          <br />
          Page Not Found
        </h1>
        <br />
        <div class="k-page-caption" style="text-align: left">
          Go Back To <router-link to="/"> The Homepage </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
a {
  color: #fff;
  font-weight: 600;
}

a:hover {
  color: #aaa;
}

.error {
  font-size: 120px;
}

.k-page-title-container {
  display: grid;
  align-content: center;
  justify-content: flex-start;
  height: 100vh;
}
</style>