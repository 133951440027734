<template>
  <div class="k-mobile-header-wrap">
    <div
      class="
        k-mobile-header
        k-header-background
        k-style-slide
        k-sticky-mobile-navigation
      "
      id="k-mobile-header"
    >
      <div class="k-mobile-header-container k-container clearfix">
        <div class="k-logo k-item-pdlr">
          <div class="k-logo-inner">
            <router-link to="/"
              ><img src="/images/bandalogo-1.png" style="width: 60px"
            /></router-link>
          </div>
        </div>

        <div class="k-mobile-menu-right">
          <div class="k-mobile-menu">
            <a
              class="
                k-mm-menu-button k-mobile-menu-button k-mobile-button-hamburger
              "
              style="margin: 0; float: right"
              href="#k-mobile-menu"
              ><span></span
            ></a>
            <div class="k-top-bar-left k-item-pdlr" style="float: right">
              <div class="kdropdown-tab kjs clearfix">
                <div class="kdropdown-tab-title" style="color: #fff">
                  <span class="khead" style="color: #fff">
                    {{ lang.activeLang }}
                  </span>
                  <div class="kdropdown-tab-head-wrap">
                    <div
                      @click="changeLanguage('en')"
                      class="kdropdown-tab-head"
                      :class="{ kactive: enActive }"
                    >
                      {{ lang.english }}
                    </div>
                    <div
                      @click="changeLanguage('ar')"
                      class="kdropdown-tab-head"
                      :class="{ kactive: arActive }"
                    >
                      {{ lang.arabic }}
                    </div>
                    <div
                      @click="changeLanguage('ku')"
                      class="kdropdown-tab-head"
                      :class="{ kactive: kuActive }"
                    >
                      {{ lang.kurdish }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="k-mm-menu-wrap k-navigation-font"
              id="k-mobile-menu"
              :data-slide="slide"
            >
              <ul id="menu-main-navigation" class="m-menu">
                <li class="menu-item menu-item-home k-normal-menu">
                  <router-link to="/">{{ lang.home }}</router-link>
                </li>
                <li class="menu-item k-normal-menu">
                  <router-link to="/about">{{ lang.about }}</router-link>
                </li>
                <li class="menu-item k-normal-menu">
                  <router-link to="/services" class="sf-with-ul-pre">
                    {{ lang.ourServices }}
                  </router-link>
                </li>
                <li class="menu-item k-normal-menu">
                  <router-link to="/careers">{{ lang.careers }}</router-link>
                </li>

                <li class="menu-item k-normal-menu">
                  <router-link to="/contact">{{ lang.contactUs }}</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["lang", "language"],
  emits: ["changeLang"],
  computed: {
    slide() {
      if (!this.language || this.language === "") {
        return "right";
      }
      return this.language === "en" ? "right" : "left";
    },
    enActive() {
      return this.language === "en";
    },
    kuActive() {
      return this.language === "ku";
    },
    arActive() {
      return this.language === "ar";
    },
  },
  methods: {
    changeLanguage(lang) {
      this.$emit("changeLang", lang);
    },
  },
};
</script>