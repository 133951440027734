import router from './router/index'


const api = {
  async get(url) {

    try {
      const response = await send(url, 'GET')
      const responseData = await JSON.parse(response)
      return responseData
    } catch (error) {
      // console.log(error);
      throw new Error(error)
    }
  },

}

export default api

async function send(url, method) {
  // console.log(url);
  let result = new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest()
    xhr.open(method, url, true)
    xhr.send()
    xhr.onloadend = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response)
      } else if (xhr.status == 404) {
        router.replace('/notfound')
      } else {
        reject(JSON.stringify({
          status: xhr.status,
          response: xhr.response
        }))
      }
    }
  })
  return result
}