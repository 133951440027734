<template>
  <div
    v-if="background"
    class="k-page-title-wrap contact-bg k-style-custom k-center-align"
    :style="`background-image: url(${photoPath + background.photo})`"
  >
    <div class="k-header-transparent-substitute"></div>
    <div class="k-page-title-overlay"></div>
    <div class="k-page-title-container k-container">
      <div class="k-page-title-content k-item-pdlr">
        <h1 class="k-page-title" style="text-align: left">
          <br /><br />
          {{ background.title }}
        </h1>
        <div class="k-page-caption" style="text-align: left">
          {{ background.content }}
        </div>
      </div>
    </div>
  </div>
  <div class="k-page-wrapper" id="k-page-wrapper">
    <div class="kpage-builder-body">
      <div
        class="kpbf-wrapper"
        style="
          padding: 4px 0px 26px 0px;
          border-width: 0px 0px 1px 0px;
          border-color: #ededed;
          border-style: solid;
        "
      >
        <div class="kpbf-background-wrap"></div>
        <div class="kpbf-wrapper-content kjs">
          <div class="kpbf-wrapper-container clearfix kcontainer">
            <div class="kpbf-element">
              <div
                class="kbreadcrumbs-item kitem-pdlr kitem-pdb"
                style="padding-bottom: 0px"
              >
                <a class="home"
                  ><span>{{ lang.home }}</span></a
                >

                /
                <a class="post post-page"
                  ><span> {{ lang.contactUs }}</span></a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="kpbf-wrapper" style="padding: 45px 0px 35px 0px">
        <div
          class="kpbf-background-wrap"
          style="background-color: #ffffff"
        ></div>
        <div class="kpbf-wrapper-content" v-if="info">
          <div class="kpbf-wrapper-container clearfix kcontainer">
            <div class="kpbf-column kcolumn-20 kcolumn-first">
              <div
                class="kpbf-column-content-margin"
                style="padding: 50px 20px 0px 20px"
              >
                <div class="kpbf-column-content clearfix">
                  <div class="kpbf-element">
                    <div class="kicon-item kitem-pdlr kitem-pdb kleft-align">
                      <i
                        class="kicon-item-icon fa fa-phone"
                        style="
                          color: #202020;
                          font-size: 40px;
                          min-width: 40px;
                          min-height: 40px;
                        "
                      ></i>
                    </div>
                  </div>
                  <div class="kpbf-element">
                    <div
                      class="
                        ktitle-item
                        kitem-pdb
                        clearfix
                        kleft-align
                        ktitle-item-caption-top
                        kitem-pdlr
                      "
                      style="padding-bottom: 25px"
                    >
                      <div class="ktitle-item-title-wrap">
                        <h3
                          class="ktitle-item-title kskin-title"
                          style="
                            font-size: 23px;
                            font-weight: 700;
                            letter-spacing: 0px;
                            text-transform: none;
                          "
                        >
                          {{ lang.callUs }}
                          <span
                            class="ktitle-item-title-divider kskin-divider"
                          ></span>
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div class="kpbf-element">
                    <div
                      class="ktext-box-item kitem-pdlr kitem-pdb kleft-align"
                    >
                      <div
                        class="ktext-box-item-content"
                        style="font-size: 16px; text-transform: none"
                      >
                        <span v-html="info.phone"> </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="kpbf-column kcolumn-20">
              <div
                class="kpbf-column-content-margin kjs"
                style="padding: 50px 20px 0px 20px"
              >
                <div class="kpbf-column-content clearfix">
                  <div class="kpbf-element">
                    <div class="kicon-item kitem-pdlr kitem-pdb kleft-align">
                      <i
                        class="kicon-item-icon fa fa-envelope-o"
                        style="
                          color: #202020;
                          font-size: 40px;
                          min-width: 40px;
                          min-height: 40px;
                        "
                      ></i>
                    </div>
                  </div>
                  <div class="kpbf-element">
                    <div
                      class="
                        ktitle-item
                        kitem-pdb
                        clearfix
                        kleft-align
                        ktitle-item-caption-top
                        kitem-pdlr
                      "
                      style="padding-bottom: 25px"
                    >
                      <div class="ktitle-item-title-wrap">
                        <h3
                          class="ktitle-item-title kskin-title"
                          style="
                            font-size: 23px;
                            font-weight: 700;
                            letter-spacing: 0px;
                            text-transform: none;
                          "
                        >
                          {{ lang.emailUs
                          }}<span
                            class="ktitle-item-title-divider kskin-divider"
                          ></span>
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div class="kpbf-element">
                    <div
                      class="ktext-box-item kitem-pdlr kitem-pdb kleft-align"
                    >
                      <div
                        class="ktext-box-item-content"
                        style="font-size: 16px"
                      >
                        <a :href="`mailto:${info.email}`">{{ info.email }}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="kpbf-column kcolumn-20">
              <div
                class="kpbf-column-content-margin"
                style="padding: 50px 20px 0px 20px"
              >
                <div class="kpbf-column-content clearfix kjs">
                  <div class="kpbf-element">
                    <div class="kicon-item kitem-pdlr kitem-pdb kleft-align">
                      <i
                        class="kicon-item-icon fa fa-location-arrow"
                        style="
                          color: #202020;
                          font-size: 40px;
                          min-width: 40px;
                          min-height: 40px;
                        "
                      ></i>
                    </div>
                  </div>
                  <div class="kpbf-element">
                    <div
                      class="
                        ktitle-item
                        kitem-pdb
                        clearfix
                        kleft-align
                        ktitle-item-caption-top
                        kitem-pdlr
                      "
                      style="padding-bottom: 25px"
                    >
                      <div class="ktitle-item-title-wrap">
                        <h3
                          class="ktitle-item-title kskin-title"
                          style="
                            font-size: 23px;
                            font-weight: 700;
                            letter-spacing: 0px;
                            text-transform: none;
                          "
                        >
                          {{ lang.findUs
                          }}<span
                            class="ktitle-item-title-divider kskin-divider"
                          ></span>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="kpbf-element">
                    <div
                      class="ktext-box-item kitem-pdlr kitem-pdb kleft-align"
                      style="padding-bottom: 0px"
                    >
                      <div
                        class="ktext-box-item-content"
                        style="font-size: 16px"
                      >
                        <p v-for="(address, index) in addresses" :key="index">
                          {{ address.address }}
                          <br />
                          <a
                            :href="`https://www.google.com.sa/maps/search/${address.coordinates}`"
                            target="_blank"
                            style="font-weight: bold"
                            >{{ lang.viewOnMap }}</a
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["lang", "photoPath"],
  data() {
    return {
      loading: false,
    };
  },
  async mounted() {
    setTimeout(async () => {
      if (!this.info) {
        await this.$store.dispatch("contact/contact");
      }
    }, 1000);
  },
  computed: {
    info() {
      return this.$store.getters["contact/info"];
    },
    background() {
      return this.$store.getters["contact/background"];
    },
    addresses() {
      return this.$store.getters["contact/addresses"];
    },
  },
};
</script>