<template>
  <TheLoader v-if="loading" />
  <div
    v-if="background"
    class="k-page-title-wrap k-style-custom k-center-align"
    :style="`background-image: url(${photoPath + background.photo})`"
  >
    <div class="k-header-transparent-substitute"></div>
    <div class="k-page-title-overlay"></div>
    <div class="k-page-title-container k-container">
      <div class="k-page-title-content k-item-pdlr">
        <h1 class="k-page-title" style="text-align: left">
          <br /><br />
          {{ background.title }}
        </h1>
        <div class="k-page-caption" style="text-align: left">
          {{ background.content }}
        </div>
      </div>
    </div>
  </div>

  <div class="k-page-wrapper" id="k-page-wrapper" v-if="about">
    <div class="kpage-builder-body">
      <div
        class="kpbf-wrapper"
        style="
          padding: 4px 0px 26px 0px;
          border-width: 0px 0px 1px 0px;
          border-color: #ededed;
          border-style: solid;
        "
      >
        <div class="kpbf-background-wrap"></div>
        <div class="kpbf-wrapper-content kjs">
          <div class="kpbf-wrapper-container clearfix kcontainer">
            <div class="kpbf-element">
              <div
                class="kbreadcrumbs-item kitem-pdlr kitem-pdb"
                style="padding-bottom: 0px"
              >
                <a class="home"
                  ><span>{{ lang.home }}</span></a
                >

                /
                <a class="post post-page"
                  ><span> {{ lang.aboutUs }}</span></a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="kpbf-sidebar-wrapper">
        <div
          class="kpbf-sidebar-container kline-height-0 clearfix kjs kcontainer"
        >
          <div
            class="
              kpbf-sidebar-content kpbf-sidebar-padding
              kline-height
              kcolumn-extend-left
            "
            style="padding: 50px 0px 30px 0px"
          >
            <div class="kpbf-sidebar-content-inner">
              <div class="kpbf-element">
                <div
                  class="
                    ktitle-item
                    kitem-pdb
                    clearfix
                    kleft-align
                    ktitle-item-caption-top
                    kitem-pdlr
                  "
                  style="padding-bottom: 35px"
                >
                  <div class="ktitle-item-title-wrap">
                    <h3
                      class="ktitle-item-title kskin-title"
                      style="
                        font-size: 27px;
                        font-weight: 700;
                        letter-spacing: 0px;
                        text-transform: none;
                        color: #202020;
                      "
                    >
                      {{ about.title
                      }}<span
                        class="ktitle-item-title-divider kskin-divider"
                      ></span>
                    </h3>
                  </div>
                </div>
              </div>
              <div class="kpbf-element">
                <div
                  class="
                    ktext-box-item
                    kitem-pdlr kitem-pdb
                    kleft-align
                    kno-p-space
                  "
                >
                  <div
                    class="ktext-box-item-content"
                    style="
                      font-size: 17px;
                      font-weight: 400;
                      text-transform: none;
                      color: #777777;
                      margin-bottom: 50px;
                    "
                  >
                    <span v-html="about.content"> </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import TheLoader from "../components/TheLoader.vue";

export default {
  components: { TheLoader },
  inject: ["lang", "photoPath"],
  data() {
    return {
      loading: true,
    };
  },
  async mounted() {
    await this.$store.dispatch("about/about");
    this.loading = false;
  },
  computed: {
    about() {
      return this.$store.getters["about/about"];
    },
    background() {
      return this.$store.getters["about/background"];
    },
  },
};
</script>