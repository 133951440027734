import { createStore } from "vuex";

import Services from "./services";
import Careers from "./careers";
import About from "./about";
import Contact from "./contact";

import useApi from "../useApi";

export default createStore({
  modules: {
    services: Services,
    careers: Careers,
    about: About,
    contact: Contact,
  },
  state: {
    main: null,
    texts: [],
    background: null,
    language: null,
    server: "https://bandacargo.kurdos.co/api/",
  },
  getters: {
    main: (state) => {
      return state.main;
    },
    language: (state) => {
      return state.language;
    },
    texts: (state) => {
      return state.texts;
    },
    background: (state) => {
      return state.background;
    },
    url: (state) => (action) => {
      let lang = localStorage.getItem("lang");
      if (!lang || lang == "") lang = "en";
      let url = state.server;
      url += action + "?lang=" + lang;
      return url;
    },
  },
  mutations: {
    setHome: (state, payload) => {
      state.main = payload.text;
      state.texts = payload.texts;
      state.background = payload.background;
    },
    setLanguage(state, payload) {
      state.language = payload;
    },
  },
  actions: {
    home: async (context) => {
      const url = context.rootGetters.url("home");
      const response = await useApi.get(url);
      context.commit("setHome", response);
    },
    setLanguage(context, payload) {
      console.log(payload);
      context.commit("setLanguage", payload);
    },
  },
});
