<template>
  <TheLoader v-if="loading" />
  <div
    v-if="background"
    class="k-page-title-wrap k-style-custom k-center-align"
    :style="`background-image: url(${photoPath + background.photo})`"
  >
    <div class="k-header-transparent-substitute"></div>
    <div class="k-page-title-overlay"></div>
    <div class="k-page-title-container k-container">
      <div class="k-page-title-content k-item-pdlr">
        <h1 class="k-page-title" style="text-align: left">
          <br /><br />
          {{ background.title }}
        </h1>
        <div class="k-page-caption" style="text-align: left">
          {{ background.content }}
        </div>
      </div>
    </div>
  </div>
  <div class="k-page-wrapper" id="k-page-wrapper">
    <div class="kpage-builder-body">
      <div
        class="kpbf-wrapper"
        style="
          padding: 4px 0px 26px 0px;
          border-width: 0px 0px 1px 0px;
          border-color: #ededed;
          border-style: solid;
        "
      >
        <div class="kpbf-background-wrap"></div>
        <div class="kpbf-wrapper-content kjs">
          <div class="kpbf-wrapper-container clearfix kcontainer">
            <div class="kpbf-element">
              <div
                class="kbreadcrumbs-item kitem-pdlr kitem-pdb"
                style="padding-bottom: 0px"
              >
                <a class="home"
                  ><span>{{ lang.home }}</span></a
                >

                /
                <a class="post post-page"
                  ><span> {{ lang.ourServices }}</span></a
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <Services />
    </div>
  </div>
</template>

<script>
import Services from "../components/Services.vue";
import TheLoader from "../components/TheLoader.vue";

export default {
  inject: ["lang", "photoPath"],
  components: { Services, TheLoader },
  data() {
    return {
      loading: true,
    };
  },
  async mounted() {
    await this.$store.dispatch("services/background");
    this.loading = false;
  },
  computed: {
    background() {
      return this.$store.getters["services/background"];
    },
  },
};
</script>