<template>
  <div id="loader">
    <FulfillingSquareSpinner
      :animation-duration="6000"
      :size="60"
      color="#202020"
    />
  </div>
</template>

<script>
import { FulfillingSquareSpinner } from "epic-spinners";

export default {
  components: { FulfillingSquareSpinner },
  mounted() {
    document.querySelector("footer").style.display = "none";
  },
  unmounted() {
    document.querySelector("footer").style.display = "";
  },
};
</script>

<style>
#loader {
  background-color: #fff;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  display: grid;
  place-items: center;
  position: fixed;
}
</style>