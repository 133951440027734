<template>
  <footer>
    <div class="k-copyright-wrapper">
      <div class="k-copyright-container k-container clearfix">
        <div class="k-copyright-left k-item-pdlr" v-if="enActive">
          {{ year }} &copy; B&A Cargo | Developed by
          <a href="https://www.blackace.tech" target="_blank"> Black Ace </a>
        </div>
        <div class="k-copyright-left k-item-pdlr" v-else>
          <a href="https://www.blackace.tech" target="_blank"> Black Ace </a>
          {{ lang.footerDesc }} {{ year }}
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  inject: ["lang", "language"],
  computed: {
    year() {
      return new Date().getFullYear();
    },
    enActive() {
      return this.language === "en";
    },
  },
};
</script>

<style>
footer {
  position: relative;
  z-index: 10;
  bottom: 0px;
}
</style>